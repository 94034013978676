import React, { useEffect, useState, useReducer } from 'react';
import { Table, Input, Card } from 'antd';
import { getUsers } from '../../api';
import MaterialTags from '../crush/components/material-tags';

const { Search } = Input;

export default function() {
  const columns = [
    {
      title: '工号',
      dataIndex: 'wwcUserId',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      render(_, row) {
        return <img style={{ width: '60px' }} src={row.avatar} alt="avatar" />;
      },
    },
    {
      title: '店铺',
      dataIndex: 'storeName',
    },
    {
      title: '职位',
      dataIndex: 'position',
    },
    {
      title: '所有材料',
      render(_, row) {
        return <MaterialTags {...row.totalMaterials} />;
      },
    },
    {
      title: '完成任务',
      render(_, row) {
        return row.completedTaskIds.length;
      },
    },
    {
      title: '打造Crush',
      render(_, row) {
        return row.crushIds.length;
      },
    },
    {
      title: '总积分',
      dataIndex: 'points',
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const load = async (kw = '') => {
    const { data, count } = await getUsers({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
      keyword: kw,
    });
    setDataSource(data);
    setTotal(count);
  };
  const handlePaginationChange = (params) => {
    setPagination(params);
  };
  const handleSearch = (value) => {
    load(value);
  };

  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <Card style={{ marginBottom: '20px' }}>
        <Search style={{ width: '50%' }} placeholder="根据姓名或工号搜索" enterButton="搜索" size="large" onSearch={handleSearch} />
      </Card>
      <Table columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} rowKey="id" />
    </>
  );
}
