import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Modal, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import isBewteen from 'dayjs/plugin/isBetween';
import { getTasks, submitTask } from '../../api';
import { getRangeDetail } from '../../store/actions';

dayjs.extend(isBewteen);

const { Option } = Select;

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [taskModel, setTaskModel] = useReducer((state, val) => val, {});
  const ranges = useSelector((state) => state.ranges);
  const dispatch = useDispatch();
  const handleEditTask = (row) => {
    setTaskModel(row);
    setModalVisible(true);
  };
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '外部 ID',
      dataIndex: 'externalId',
    },
    {
      title: '类型',
      render(_, data) {
        switch (data.taskType) {
          case 'TEXT':
            return '文字答题';
          case 'VIDEO':
            return '视频答题';
          case 'PUZZLE':
            return '拼图游戏';
          case 'SEARCH':
            return '寻找CRUSH';
          default:
            return '未知';
        }
      },
    },
    {
      title: '开放阶段',
      render(_, data) {
        switch (data.range) {
          case 1:
            return '第一阶段';
          case 2:
            return `第二阶段`;
          case 3:
            return '第三阶段';
          default:
            return '未知';
        }
      },
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <Button type="link" onClick={() => handleEditTask(row)}>
            编辑
          </Button>
        );
      },
    },
  ];

  const load = async () => {
    const { data, count } = await getTasks({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
    });
    setDataSource(data);
    setTotal(count);
    const needLoadRanges = [];
    data.forEach((item) => {
      if (needLoadRanges.indexOf(item.range) === -1) {
        needLoadRanges.push(item.range);
      }
    });
    needLoadRanges.forEach((r) => dispatch(getRangeDetail(r)));
  };
  const handleTableChange = (params) => {
    setPagination(params);
  };
  const handleSubmitTask = () => {
    return form.validateFields().then(async (values) => {
      const data = {
        ...taskModel,
        ...values,
      };
      return submitTask(data);
    });
  };
  const handleModalOk = async () => {
    await handleSubmitTask();
    setModalVisible(false);
    load();
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };
  const isRangeActive = (range) => {
    if (!ranges[range]) return false;
    const { start, end } = ranges[range];
    return dayjs().isBetween(dayjs(start), dayjs(end));
  };
  useEffect(() => {
    if (modalVisible) form.setFieldsValue(taskModel);
  }, [modalVisible]);
  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <Table dataSource={dataSource} columns={columns} rowKey="id" pagination={{ ...pagination, total }} onChange={handleTableChange} />
      <Modal title="任务编辑" visible={modalVisible} forceRender={true} onCancel={handleCancelModal} onOk={handleModalOk} okText="提交" cancelText="取消">
        <Form initialValues={taskModel} form={form} layout="vertical">
          <Form.Item
            name="name"
            label="任务名称"
            rules={[
              {
                required: true,
                message: '请输入任务名称',
              },
            ]}
          >
            <Input placeholder="请输入任务名称" />
          </Form.Item>

          <Form.Item name="externalId" label="外部 ID">
            <Input placeholder="请输入外部 ID" disabled={isRangeActive(taskModel.range)} />
          </Form.Item>

          <Form.Item name="taskType" label="任务类型">
            <Select style={{ width: 120 }} disabled={isRangeActive(taskModel.range)}>
              <Option value="TEXT">TEXT</Option>
              <Option value="VIDEO">VIDEO</Option>
              <Option value="PUZZLE">PUZZLE</Option>
              <Option value="SEARCH">SEARCH</Option>
            </Select>
          </Form.Item>

          <Form.Item name="range" label="开放阶段">
            <Select style={{ width: 120 }} disabled={isRangeActive(taskModel.range)}>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
