import React, { useState } from 'react';
import UserSelector from './components/user-selector';
import { Tabs, Card, Tag, Button, Input, Form, message } from 'antd';
import classnames from 'classnames';
import { TagOutlined, TeamOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import ImgUploader from '../../components/img-uploader';
import RichEditor from '../../components/rich-editor';
import styles from './msg.module.css';
import { sendNotifications, publishHTML } from '../../api';
import { buildHTML } from '../../utils';

const { TabPane } = Tabs;
const { TextArea } = Input;

export default function() {
  const [visible, setVisible] = useState(false);
  const [sendTo, setSendTo] = useState([]);
  const [text, setText] = useState('');
  const [image, setImage] = useState('');
  const [activeKey, setActiveKey] = useState('text');
  const [htmlForm] = Form.useForm();
  const [news, setNews] = useState([{ title: '标题', content: '', picUrl: '', description: '简介' }]);
  const [activeNews, setActiveNews] = useState(0);

  const handleVisibleClick = () => {
    setVisible(true);
  };
  const handleModalCancel = () => {
    setVisible(false);
  };
  const handleModalConfirm = (res) => {
    setSendTo(res);
    setVisible(false);
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  const buildNewsBody = async (values) => {
    for (let i = 0; i < news.length; i++) {
      if (!news[i].title) {
        message.error(`第${i + 1}条标题未填写`);
        return Promise.reject();
      }
      if (!news[i].picUrl) {
        message.error(`第${i + 1}条封面图未上传`);
        return Promise.reject();
      }
      if (!news[i].description) {
        message.error(`第${i + 1}条简介未填写`);
        return Promise.reject();
      }
      if (!news[i].content) {
        message.error(`第${i + 1}条内容未填写`);
        return Promise.reject();
      }
    }
    const afterNews = news.map((item, index) => {
      return index === activeNews ? { ...item, values } : item;
    });
    setNews(afterNews);
    const body = afterNews.map((n) => ({
      ...n,
      html: buildHTML(n),
    }));
    const htmls = await Promise.all(body.map(({ html }) => publishHTML(html)));
    return body.map((item, index) => ({
      ...item,
      url: htmls[index].publishedUrlOnCDN,
    }));
  };
  const handleSubmit = async () => {
    if (sendTo.length <= 0) {
      message.error('请选择发送范围');
      return;
    }
    const body = {
      toUser: [],
      toParty: [],
      toTag: [],
      msgType: activeKey,
    };
    sendTo.forEach((item) => {
      if (item.isUser) body.toUser.push(item.id);
      if (item.isDepartment) body.toParty.push(item.id);
      if (item.isTag) body.toTag.push(item.id);
    });
    switch (activeKey) {
      case 'text':
        if (!text) {
          message.error('请填写文字内容');
          return;
        }
        body.text = {
          content: text,
        };
        break;
      case 'image':
        if (!image) {
          message.error('请上传图片');
          return;
        }
        body.image = {
          mediaId: image.mediaId,
          imageUrl: image.url,
        };
        break;
      case 'news':
        await htmlForm.validateFields();
        const values = htmlForm.getFieldsValue();
        const newsBody = await buildNewsBody(values);
        body.news = {
          articles: newsBody,
        };
        break;
      default:
      // 消息类型错误
    }
    await sendNotifications(body);
  };
  const handleImageChange = (file) => {
    if (!file) setImage('');
    else setImage(file);
  };
  const handleNewsContentChange = (values) => {
    setNews(
      news.map((item, index) => {
        return index === activeNews ? { ...item, ...values } : item;
      })
    );
  };
  const handleAddNews = () => {
    const afterNews = [...news, { title: '标题', description: '简介', content: '', picUrl: '' }];
    setNews(afterNews);
    setActiveNews(afterNews.length - 1);
    htmlForm.resetFields();
  };
  const handleClickNewsItem = (index) => {
    setActiveNews(index);
    htmlForm.setFieldsValue(news[index]);
  };
  return (
    <div>
      <div className={styles.cardWrapper}>
        <Card>
          {sendTo.length > 0 && (
            <div className={styles.sendToWrapper}>
              <ul className={styles.sendTo}>
                <li>发送范围：</li>
                {sendTo.map((node) => (
                  <li key={node.key}>
                    <Tag>
                      {node.isTag && <TagOutlined className="mr-1" />}
                      {node.isDepartment && <TeamOutlined className="mr-1" />}
                      {node.isUser && <UserOutlined className="mr-1" />}
                      {node.title}
                    </Tag>
                  </li>
                ))}
                <li>
                  <Button type="link" onClick={handleVisibleClick}>
                    修改
                  </Button>
                </li>
              </ul>
            </div>
          )}
          {sendTo.length <= 0 && (
            <Button type="link" onClick={handleVisibleClick}>
              选择发送范围
            </Button>
          )}
        </Card>
      </div>
      <div className={styles.cardWrapper}>
        <Card>
          <Tabs onChange={handleTabChange}>
            <TabPane tab="文字" key="text">
              内容：
              <TextArea value={text} onChange={handleTextChange} placeholder="请输入文本信息" autoSize={{ minRows: 3, maxRows: 5 }} />
            </TabPane>
            <TabPane tab="图片" key="image">
              <ImgUploader useMediaId={true} onChange={handleImageChange} />
            </TabPane>
            <TabPane tab="图文 H5" key="news">
              <div className="flex items-start">
                <div className={styles.newsEdit}>
                  <Form form={htmlForm} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} onValuesChange={handleNewsContentChange}>
                    <Form.Item name="title" label="标题" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="picUrl" label="封面图" rules={[{ required: true }]}>
                      <ImgUploader />
                    </Form.Item>
                    <Form.Item name="description" label="简介" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="content" label="内容" rules={[{ required: true }]}>
                      <RichEditor />
                    </Form.Item>
                  </Form>
                </div>
                <div className={styles.newsSelector}>
                  {news.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleClickNewsItem(index)}
                      className={classnames(styles.newsItem, {
                        [styles.newsItemActive]: index === activeNews,
                        [styles.firstNewsItem]: index === 0,
                        [styles.multiNewsItem]: news.length > 1,
                      })}
                    >
                      <div className={styles.newsItemImg}>{item.picUrl && <img src={item.picUrl} />}</div>
                      <div className={styles.newsItemTitle}>
                        <div>{item.title}</div>
                      </div>
                      <div className={styles.newsItemDesc}>{item.description}</div>
                    </div>
                  ))}
                  <Button onClick={handleAddNews} block>
                    <PlusOutlined />
                  </Button>
                </div>
              </div>
            </TabPane>
          </Tabs>
          <Button type="primary" onClick={handleSubmit}>
            提交
          </Button>
        </Card>
      </div>
      <UserSelector visible={visible} onCancel={handleModalCancel} onConfirm={handleModalConfirm} />
    </div>
  );
}
