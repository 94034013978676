import React, { useState, useEffect } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import urljoin from 'url-join';
import { getOSSPolicy, ossImage2Wechat } from '../../api';
import { CDN_HOST } from '../../consts';

const UploadBtn = () => (
  <Button>
    <UploadOutlined /> 点击上传
  </Button>
);

export default function(props) {
  const getFileName = (url) => {
    const arr = url.split('/');
    return arr[arr.length - 1];
  };
  const formatFileList = (list) => {
    if (!list) return [];
    if (Array.isArray(list)) {
      return list.map((item, index) => ({
        uid: index,
        name: getFileName(item),
        status: 'done',
        url: item,
      }));
    } else {
      return [
        {
          uid: 0,
          name: getFileName(list),
          status: 'done',
          url: list,
        },
      ];
    }
  };
  const [OSSData, setOSSData] = useState({});
  const init = async () => {
    const res = await getOSSPolicy();
    setOSSData(res);
  };
  const handleBeforeUpload = async () => {
    await init();
    return true;
  };
  const transformFile = (file) => {
    // eslint-disable-next-line no-param-reassign
    file.url = urljoin(CDN_HOST, OSSData.dir, file.name);
    return file;
  };
  const handleFileChange = async ({ file, fileList }) => {
    if (file.status === 'done' || file.status === 'removed') {
      if (file.status === 'done' && props.useMediaId) {
        const { mediaId } = await ossImage2Wechat(urljoin(OSSData.dir, file.name));
        file.mediaId = mediaId;
      }
      if (props.isMulti) {
        if (props.useMediaId) props.onChange(fileList);
        else props.onChange(fileList.map((item) => item.url));
      } else {
        const finalFile = props.useMediaId ? file : file.url;
        props.onChange(file.status === 'removed' ? null : finalFile);
      }
    }
  };
  const getExtraData = (file) => ({
    key: OSSData.dir + file.name,
    OSSAccessKeyId: OSSData.accessid,
    policy: OSSData.policy,
    Signature: OSSData.signature,
  });
  return (
    <Upload
      transformFile={transformFile}
      action={OSSData.host}
      beforeUpload={handleBeforeUpload}
      onChange={handleFileChange}
      listType="picture-card"
      defaultFileList={formatFileList(props.value)}
      data={getExtraData}
    >
      <UploadBtn />
    </Upload>
  );
}
