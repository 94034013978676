import React, { useEffect, useState, useReducer } from 'react';
import { Table, Button, notification, Modal } from 'antd';
import dayjs from 'dayjs';
import { getTaskLogs, completeByAdmin } from '../../api';

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);

  const load = async () => {
    const { data, count } = await getTaskLogs({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
    });
    setDataSource(data);
    setTotal(count);
  };
  const handleSendReward = (row) => {
    Modal.confirm({
      content: '是否确认发放？',
      async onOk() {
        await completeByAdmin({
          taskId: row.taskId,
          userId: row.user.id,
        });
        notification.success({ message: '发放成功' });
        load();
      },
    });
  };
  const columns = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
    },
    {
      title: '头像',
      render(_, row) {
        return <img style={{ width: '100px' }} src={row.user.avatar} alt="avatar" />;
      },
    },
    {
      title: '店铺',
      dataIndex: ['user', 'storeName'],
    },
    {
      title: '职位',
      dataIndex: ['user', 'position'],
    },
    {
      title: '任务名称',
      dataIndex: ['task', 'name'],
    },
    {
      title: '任务类型',
      render(_, data) {
        switch (data.task && data.task.taskType) {
          case 'TEXT':
            return '文字答题';
          case 'VIDEO':
            return '视频答题';
          case 'PUZZLE':
            return '拼图游戏';
          case 'SEARCH':
            return '寻找CRUSH';
          default:
            return '未知';
        }
      },
    },
    {
      title: 'CRUSH ID',
      // dataIndex: 'crushId',
      render(_, data) {
        return data.crushId || 'N/A';
      },
    },
    {
      title: '奖励内容',
      dataIndex: 'rewardType',
      render(_, data) {
        switch (data.rewardType) {
          case 'PRINT':
            return '设计图纸';
          case 'MOULD':
            return '打造模具';
          default:
            return '未知';
        }
      },
    },
    {
      title: '已完成',
      render(_, row) {
        return !!row.completedAt ? '是' : '否';
      },
    },
    {
      title: '无效奖励',
      render(_, row) {
        return row.isInvalid ? '是' : '否';
      },
    },
    {
      title: '完成日期',
      render(_, row) {
        return row.completedAt ? dayjs(row.completedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A';
      },
    },

    {
      title: '操作',
      render(_, row) {
        return (
          <Button disabled={row.crushId} type="link" onClick={() => handleSendReward(row)}>
            发放奖励
          </Button>
        );
      },
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const handlePaginationChange = (params) => {
    setPagination(params);
  };

  useEffect(() => {
    load();
  }, [pagination]);
  return <Table rowKey="id" columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} />;
}
