import React from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { CalendarOutlined, UserOutlined, QrcodeOutlined, UnorderedListOutlined, BuildOutlined, WechatOutlined } from '@ant-design/icons';
import { useHistory, withRouter } from 'react-router-dom';
import PropType from 'prop-types';
import styles from './navbar.module.css';

const { SubMenu } = Menu;
const { Sider } = Layout;
export const menus = [
  {
    key: 'users',
    name: '用户信息',
    description: '查看所有已授权登陆的用户的个人，材料，任务，打造，积分信息',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <UserOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>用户信息</span>
      </div>
    ),
    link: '/app/users',
  },
  {
    key: 'signin',
    name: '签到奖励',
    description: '查看所有用户的 签到，七日签到 和 老虎机 奖励的全部历史记录',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <CalendarOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>签到奖励</span>
      </div>
    ),
    link: '/app/signin',
  },
  {
    key: 'tasks',
    name: '任务',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <UnorderedListOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>任务</span>
      </div>
    ),
    children: [
      {
        link: '/app/tasks',
        title: '任务管理',
        description: '查看管理所有的任务，任务有系统初始化，开放阶段内不允许修改 任务类型, 外部id 和 开放阶段信息',
        key: 'tasks-list',
      },
      {
        link: '/app/task-log',
        title: '任务日志',
        description: '查看所有用户的任务开启和完成信息，管理员可以通过 发放奖励 为未完成用户直接发放该任务奖励',
        key: 'task-log',
      },
    ],
  },
  {
    key: 'crushs',
    name: 'CRUSH',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <BuildOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>CRUSH</span>
      </div>
    ),
    children: [
      {
        key: 'crush-list',
        link: '/app/crushes',
        title: 'CRUSH 管理',
        description: '查看管理所有的CRUSH，开放阶段内不允许修改 材料数量 和 开放周期',
      },
      {
        key: 'crush-logs',
        link: '/app/crushes/logs',
        title: 'CRUSH 打造记录',
        description: '查看所有用户的CRUSH打造记录',
      },
    ],
  },
  {
    key: 'msg-push',
    name: '企业消息',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <WechatOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>企业消息</span>
      </div>
    ),
    children: [
      {
        key: 'msg-push-create',
        link: '/app/msg-push/create',
        title: '消息推送',
        description: '发送企业应用内的文字，图片，或 图文H5 消息(点击消息跳转到H5页面)',
      },
      {
        key: 'msg-push-history',
        link: '/app/msg-push',
        title: '消息记录',
        description: '查看消息发送的 类型，内容，和是否成功 的历史记录',
      },
    ],
  },
  {
    key: 'qrcode',
    name: '二维码生成',
    description: '生成制定页面的小程序二维码，生成后可以下载使用',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <QrcodeOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>二维码生成</span>
      </div>
    ),
    link: '/app/qrcode',
  },
];

function NavBar(props) {
  const logoImg = '/imgs/chanel-white.png';
  const collapsed = useSelector((state) => state.navBarCollapsed);
  const { location } = props;
  const history = useHistory();
  const handleMenuSelect = (menu) => {
    history.push(menu.key);
  };
  return (
    <Sider trigger={null} width={200} className="site-layout-background" collapsible collapsed={collapsed}>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} style={{ height: '100%', borderRight: 0 }} onClick={handleMenuSelect}>
        <a href="/" className={styles.logoContainer}>
          <img className={styles.logo} src={logoImg} alt="logo" />
        </a>
        {menus.map((menu) => {
          if (menu.children && menu.children.length > 0) {
            return (
              <SubMenu key={menu.key} title={menu.title}>
                {menu.children.map((item) => (
                  <Menu.Item key={item.link}>{item.title}</Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            return <Menu.Item key={menu.link}>{menu.title}</Menu.Item>;
          }
        })}
      </Menu>
    </Sider>
  );
}

NavBar.propTypes = {
  location: PropType.shape({
    pathname: PropType.string.isRequired,
  }).isRequired,
};

export default withRouter(NavBar);
