import React from 'react';
import 'antd/dist/antd.css';
import { Layout } from 'antd';
import { Provider } from 'react-redux';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import SignIn from './views/signin';
import Users from './views/users';
import Login from './views/login';
import Tasks from './views/tasks';
import TaskLog from './views/tasks/log';
import CreateMsg from './views/msg-push/create';
import MsgHistory from './views/msg-push';
import CrushList from './views/crush';
import CrushLogs from './views/crush/log';
import QrCode from './views/qrcode';
import styles from './App.module.css';
import Header from './components/header';
import NavBar from './components/navbar';
import store from './store';
import { history } from './api';
import Breadcrumb from './components/breadcrumb';

const { Content } = Layout;

function AppLayout() {
  return (
    <Layout className={styles.container}>
      <NavBar />
      <Layout className={styles.body}>
        <Header />
        <Layout style={{ padding: '24px' }}>
          <Content className="site-layout-background">
            <Breadcrumb />
            <Switch>
              <Redirect exact from="/app" to="/app/users" />
              <Route exact path="/app/users">
                <Users />
              </Route>
              <Route path="/app/signin">
                <SignIn />
              </Route>
              <Route path="/app/tasks">
                <Tasks />
              </Route>
              <Route path="/app/task-log">
                <TaskLog />
              </Route>
              <Route exact path="/app/msg-push">
                <MsgHistory />
              </Route>
              <Route path="/app/msg-push/create">
                <CreateMsg />
              </Route>
              <Route exact path="/app/crushes">
                <CrushList />
              </Route>
              <Route path="/app/crushes/logs">
                <CrushLogs />
              </Route>
              <Route path="/app/qrcode">
                <QrCode />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to="/app/users" />
          <Route path="/app">
            <AppLayout />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
