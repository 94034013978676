import React from 'react';
import { Tag } from 'antd';

export default function({ gold, silver, copper, diamond }) {
  return (
    <>
      <Tag color="gold">{gold || 0}</Tag>
      <Tag color="#B1AD9F">{silver || 0}</Tag>
      <Tag color="#D6A550">{copper || 0}</Tag>
      <Tag color="#009BEA">{diamond || 0}</Tag>
    </>
  );
}
