import * as API from '../api';

export const getRangeDetail = (range) => {
  return (dispatch) => {
    API.getRangeDates(range).then((res) => {
      dispatch({
        type: 'setRangeDetail',
        payload: {
          ...res,
          id: range,
        },
      });
    });
  };
};

export const setNavBarCollapsed = (collapsed) => {
  return (dispatch) => {
    dispatch({
      type: 'setNavBarCollapsed',
      payload: collapsed,
    });
  };
};
