import axios from 'axios';
import { createBrowserHistory } from 'history';
import urljoin from 'url-join';
import { notification } from 'antd';

export const history = createBrowserHistory();
window.historyins = history;
const hostMap = {
  dev: 'https://cococrush-service.dev.dozto.com/',
  stg: '',
  prod: '',
};
const APIHOST = hostMap[process.env.REACT_APP_ENV || 'dev'];

const request = axios.create({
  baseURL: APIHOST,
  withCredentials: true,
});

request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        history.push('/login');
        return Promise.reject();
      } else {
        if (err.response && err.response.data && err.response.data.error) {
          notification.error({ message: err.response.data.error.message_zhCN });
        }
        return Promise.reject(err.response);
      }
    }
  }
);

export const login = (data) => request.post('/auth/admin/login', data);

export const getUsers = (params) => request.get('/users', { params });

export const getTasks = (params) =>
  request.get('/tasks', {
    params,
  });

export const getRangeDates = (id) => {
  const q = [request.get(`/settings/RANGE_${id}_START`), request.get(`/settings/RANGE_${id}_END`)];
  return Promise.all(q).then(([start, end]) => ({
    start: start.value,
    end: end.value,
  }));
};

export const submitTask = (data) => request.put(`/tasks/${data.id}`, data);

export const getTaskLogs = (params) =>
  request.get('/task-logs', {
    params,
  });

export const getDepartments = () => request.get('/wechat-work/contacts/departments');

export const getDepartmentUsers = (id) => request.get(`/wechat-work/contacts/departments/${id}/users`);

export const getTags = () => request.get('/wechat-work/contacts/tags');

export const sendNotifications = (data) => request.post('/wechat-work/notifications', data);

export const getOSSPolicy = () => request.get('/aliyun/upload-policy');

export const ossImage2Wechat = (filePath) =>
  request.post('/wechat-work/media/actions/upload-temp-from-oss', {
    filePath,
    type: 'image',
  });

export const uploadToOSS = async (file) => {
  const OSSData = await getOSSPolicy();
  const data = new FormData();
  data.append('key', OSSData.dir + file.name);
  data.append('OSSAccessKeyId', OSSData.accessid);
  data.append('policy', OSSData.policy);
  data.append('Signature', OSSData.signature);
  data.append('file', file);
  return axios.post(OSSData.host, data).then(() => urljoin(OSSData.host, OSSData.dir, file.name));
};

export const publishHTML = (html) =>
  request.post('/web-contents/html-pages', {
    html,
  });

export const getMsgPushHistory = (params) => request.get('/wechat-work/notifications', { params });

export const getCrushes = (params) => request.get('/crushes', { params });

export const editCrush = (body) => request.put(`/crushes/${body.id}`, body);

export const completeByAdmin = (data) => request.post('/tasks/actions/complete-by-admin', data);

export const getSigninLogs = (params) => request.get('/sign-logs', { params });

export const getCrushLogs = (params) => request.get('/crush-logs', { params });

export const getQrCodeLogs = (params) => request.get('/mini-program/qrcodes', { params });

export const getMpPaths = () => request.get('/mini-program/path');

export const postQrCode = (data) => request.post('/mini-program/qrcodes', data);
