import React, { useEffect, useState, useReducer } from 'react';
import { Table, Button, notification, Modal, Select, Card } from 'antd';
import dayjs from 'dayjs';
import { getQrCodeLogs, getMpPaths, postQrCode } from '../../api';

const { Option } = Select;

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [paths, setPaths] = useState([]);
  const [select, setSelect] = useReducer(function(state, val) {
    return val;
  }, null);

  const load = async () => {
    const data = await getQrCodeLogs();
    setDataSource(data);
  };
  const loadPathList = async () => {
    const res = await getMpPaths();
    setPaths(res);
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '路径',
      dataIndex: ['params', 'path'],
    },
    {
      title: '生成日期',
      render(_, row) {
        return dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      render(_, row) {
        return (
          <Button type="link" href={row.url} target="_blank">
            下载
          </Button>
        );
      },
    },
  ];
  const handleSelect = (val) => {
    setSelect(val);
  };
  const handleCreate = () => {
    setVisible(true);
  };
  const handleSubmit = async () => {
    if (!select.value) {
      notification.error({ message: '请选择页面' });
      return;
    }
    await postQrCode({
      path: select.value,
      name: select.label,
    });
    notification.success({ message: '创建成功' });
    await load();
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    load();
    loadPathList();
  }, []);
  return (
    <>
      <Card style={{ marginBottom: '10px' }}>
        <Button type="primary" onClick={handleCreate}>
          创建
        </Button>
      </Card>
      <Table rowKey="id" columns={columns} pagination={false} dataSource={dataSource} />
      <Modal visible={visible} onOk={handleSubmit} onCancel={handleCancel}>
        <Select labelInValue={true} style={{ width: '200px' }} placeholder="请选择页面" onChange={handleSelect}>
          {paths.map((item) => (
            <Option value={item.path} key={item.path}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
