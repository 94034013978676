import React, { useEffect, useState } from 'react';
import { Tree, Input, Modal, Tag, Tabs } from 'antd';
import { TagOutlined, TeamOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import { getDepartments, getDepartmentUsers, getTags } from '../../../api';
import { departmentArrayToTree } from '../../../utils';
import styles from '../msg.module.css';

const { Search } = Input;
const { TabPane } = Tabs;
let originData = [];

export default function(props) {
  const { visible, onCancel, onConfirm } = props;
  const [treeData, setTreeData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchKw, setSearchKw] = useState('');
  const [tags, setTags] = useState([]);
  const reset = () => {
    setTreeData(originData);
    setCheckedList([]);
    setCheckedKeys([]);
    setExpandedKeys([]);
    setSearchKw('');
    setTags(tags.map((item) => ({ ...item, checked: false })));
  };
  const handleCancel = () => {
    reset();
    onCancel();
  };
  const handleConfirm = () => {
    onConfirm(checkedList);
  };
  const loadUsers = async (deparments) => {
    const q = deparments.map((item) => getDepartmentUsers(item.id));
    return await Promise.all(q);
  };
  const loadDepartments = async () => {
    const departments = await getDepartments();
    const users = await loadUsers(departments);
    const treeData = departmentArrayToTree(departments, users);
    setTreeData([treeData]);
    originData = [cloneDeep(treeData)];
  };
  const loadTags = async () => {
    const tags = await getTags();
    setTags(tags);
  };
  const handleSelect = (checked, event) => {
    let checkeds = [...checkedList];
    if (event.checked) {
      checkeds.push({ ...event.node, title: event.node.name });
    } else {
      checkeds = checkeds.filter((item) => item.key !== event.node.key);
    }
    setCheckedList(checkeds);
    setCheckedKeys(checkeds.map((item) => item.key));
  };
  const handleRemoveSelected = (node) => {
    const list = checkedList.filter((item) => item.id !== node.id);
    setCheckedList(list);
    setCheckedKeys(list.map((item) => item.key));
  };
  const findInTree = (tree, kw, parent = []) => {
    let res = [];
    tree.forEach((item) => {
      if (item.title.indexOf(kw) !== -1) {
        res = [...res, ...parent, item];
      }
      if (item.children) {
        res = [...res, ...findInTree(item.children, kw, [...parent, item])];
      }
    });
    return res;
  };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchKw(value);
  };
  const handleExpand = (keys) => {
    setExpandedKeys(keys);
  };
  const handleSelectTag = (tag) => {
    if (tag.checked) {
      setCheckedList(checkedList.filter((item) => item.id !== tag.id));
      setTags(tags.map((item) => ({ ...item, checked: tag.id === item.id ? false : item.checked })));
    } else {
      setCheckedList([
        ...checkedList,
        {
          key: `tag-${tag.id}`,
          id: tag.id,
          title: tag.name,
          isTag: true,
        },
      ]);
      setTags(tags.map((item) => ({ ...item, checked: tag.id === item.id ? true : item.checked })));
    }
  };

  const loop = (data, keys) => {
    if (!searchKw) return data;
    let res = [];
    for (let i = 0; i < data.length; i++) {
      if (keys.indexOf(data[i].key) !== -1) {
        const index = data[i].title.indexOf(searchKw);
        const beforeStr = data[i].title.substr(0, index);
        const afterStr = data[i].title.substr(index + searchKw.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="text-red-600">{searchKw}</span>
              {afterStr}
            </span>
          ) : (
            data[i].title
          );
        if (data[i].children) {
          data[i].children = loop(data[i].children, keys);
        }
        res.push({
          ...data[i],
          title,
        });
      }
    }
    return res;
  };

  useEffect(() => {
    loadDepartments();
    loadTags();
  }, []);
  useEffect(() => {
    if (searchKw) {
      const origin = cloneDeep(originData);
      const res = findInTree(origin, searchKw);
      let keys = [];
      if (res.length > 0) {
        keys = res.map((item) => item.key);
        setExpandedKeys(keys);
        const t = loop(origin, keys);
        setTreeData(t);
      }
    } else {
      setTreeData(originData);
    }
  }, [searchKw]);
  return (
    <Modal visible={visible} title="选择发送范围" onOk={handleConfirm} onCancel={handleCancel} width={600}>
      <div className="flex">
        <div className={styles.userSelectorDepartments}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="组织架构" key="1">
              <Search style={{ marginBottom: 8 }} placeholder="搜索" onChange={handleSearchChange} />
              <Tree
                expandedKeys={expandedKeys}
                checkedKeys={checkedKeys}
                checkStrictly={true}
                treeData={treeData}
                checkable
                onCheck={handleSelect}
                onExpand={handleExpand}
              />
            </TabPane>
            <TabPane tab="标签" key="2">
              <ul className={styles.tagSelector}>
                {tags.map((tag) => (
                  <li key={tag.id} onClick={() => handleSelectTag(tag)}>
                    <span>{tag.name}</span>
                    {tag.checked && <CheckOutlined />}
                  </li>
                ))}
              </ul>
            </TabPane>
          </Tabs>
        </div>
        <div className={styles.userSelectorCheckedList}>
          <ul>
            {checkedList.map((node) => (
              <li key={node.key}>
                <Tag closable onClose={() => handleRemoveSelected(node)}>
                  {node.isTag && <TagOutlined className="mr-1" />}
                  {node.isDepartment && <TeamOutlined className="mr-1" />}
                  {node.isUser && <UserOutlined className="mr-1" />}
                  {node.title}
                </Tag>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}
