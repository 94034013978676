import React, { useEffect, useState, useReducer } from 'react';
import { Table, Tag, Button, Modal } from 'antd';
import { TagOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getMsgPushHistory } from '../../api';

export default function() {
  const [modalSlot, setModalSlot] = useState('');
  const [visible, setVisible] = useState(false);
  const openArticleWindow = (url) => {
    window.open(url, 'previewWindow', 'width=414,height=800');
  };
  const handleViewClick = (row) => {
    if (row.msgType === 'image') {
      setModalSlot(<img src={row.image.imageUrl} />);
      setVisible(true);
    } else if (row.msgType === 'news') {
      setModalSlot(
        row.news.articles.map((article) => (
          <div key={article.url}>
            <Button type="link" onClick={() => openArticleWindow(article.url)}>
              {article.title}
            </Button>
          </div>
        ))
      );
      setVisible(true);
    }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '类型',
      dataIndex: 'msgType',
      render(_, row) {
        switch (row.data.msgType) {
          case 'text':
            return '文字';
          case 'image':
            return '图片';
          case 'news':
            return '图文消息';
          default:
            return '-';
        }
      },
    },
    // TODO: 添加消息发送状态信息
    // 目前消息记录不需要显示发送范围信息
    // {
    //   title: '发送范围',
    //   render(_, row) {
    //     return '';
    //     // return (
    //     //   <Tag>
    //     //     {row.data.toTag && <TagOutlined className="mr-1" />}
    //     //     {row.data.isDepartment && <TeamOutlined className="mr-1" />}
    //     //     {row.data.toUser.map((user) => (
    //     //       <UserOutlined className="mr-1" />
    //     //     ))}
    //     //     {row.data.title}
    //     //   </Tag>
    //     // );
    //   },
    // },
    {
      title: '发送内容',
      render(_, row) {
        switch (row.data.msgType) {
          case 'text':
            return row.data.text.content;
          case 'image':
          case 'news':
            return (
              <Button type="link" onClick={() => handleViewClick(row.data)}>
                点击查看
              </Button>
            );
          default:
            return '';
        }
      },
    },
    {
      title: '发送时间',
      render(_, row) {
        return dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const load = async () => {
    const { data, count } = await getMsgPushHistory({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
    });
    setDataSource(data);
    setTotal(count);
  };
  const handlePaginationChange = (params) => {
    setPagination(params);
  };
  const handleCloseModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <Table rowKey="id" columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} />
      <Modal visible={visible} title="查看内容" onOk={handleCloseModal} onCancel={handleCloseModal}>
        {modalSlot}
      </Modal>
    </>
  );
}
