import React, { useEffect, useState, useReducer } from 'react';
import { Table, Input, DatePicker, Card } from 'antd';
import { getSigninLogs } from '../../api';
import dayjs from 'dayjs';
import MateriralTags from '../crush/components/material-tags';

const { Search } = Input;

export default function() {
  const columns = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
    },
    {
      title: '头像',
      render(_, row) {
        return <img style={{ width: '60px' }} src={row.user.avatar} alt="avatar" />;
      },
    },
    {
      title: '店铺',
      dataIndex: ['user', 'storeName'],
    },
    {
      title: '职位',
      dataIndex: ['user', 'position'],
    },
    {
      title: '签到日期',
      render(_, row) {
        return dayjs(row.signDate).format('YYYY-MM-DD');
      },
    },
    {
      title: '签到类型',
      render(_, row) {
        switch (row.signType) {
          case 'DAILY':
            return '每日签到';
          case 'SEVEN_DAYS':
            return `七日签到（第${row.nthDays}日）`;
          case 'LUCKY_DRAW':
            return '老虎机抽奖';
          default:
            return '未知类型';
        }
      },
    },
    {
      title: '材料奖励',
      render(_, row) {
        return <MateriralTags {...row.signBouns} />;
      },
    },
    {
      title: '积分奖励',
      dataIndex: 'points',
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState('');
  const load = async (kw = '', date) => {
    const { data, count } = await getSigninLogs({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
      keyword: kw,
      signDate: date,
    });
    setDataSource(data);
    setTotal(count);
  };
  const handlePaginationChange = (params) => {
    setPagination(params);
  };
  const handleSearch = (value) => {
    load(value, date);
  };
  const handleDateChange = (date, str) => {
    setDate(str);
  };

  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <Card style={{ marginBottom: '20px' }}>
        <DatePicker size="large" onChange={handleDateChange} placeholder="签到日期" />
        <Search style={{ width: '50%' }} placeholder="根据用户姓名搜索" enterButton="搜索" size="large" onSearch={handleSearch} />
      </Card>
      <Table columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} rowKey="id" />
    </>
  );
}
