import React, { useEffect, useState, useReducer } from 'react';
import { Table, Button, Modal, Form, Input, Select, InputNumber, notification } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useSelector, useDispatch } from 'react-redux';
import ImageUploader from '../../components/img-uploader';
import MaterialTags from './components/material-tags';
import { getCrushes, editCrush } from '../../api';
import styles from './crush.module.css';
import { getRangeDetail } from '../../store/actions';

dayjs.extend(isBetween);

const { Option } = Select;

export default function() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useReducer((state, val) => val, {
    images: {},
  });
  const ranges = useSelector((state) => state.ranges);
  const dispatch = useDispatch();
  const handleEdit = (crush) => {
    setModel(crush);
    setVisible(true);
  };
  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
    },
    {
      title: '图片',
      render(_, row) {
        return row.images.completed ? <img style={{ width: '100px' }} src={row.images.completed} /> : '';
      },
    },
    {
      title: '材料',
      render(_, row) {
        return <MaterialTags {...row} />;
      },
    },
    {
      title: '积分',
      dataIndex: 'points',
    },
    {
      title: '所在位置',
      dataIndex: 'position',
    },
    {
      title: '开放阶段',
      render(_, data) {
        switch (data.range) {
          case 1:
            return '第一阶段';
          case 2:
            return `第二阶段`;
          case 3:
            return '第三阶段';
          default:
            return '未知';
        }
      },
    },
    {
      title: '操作',
      render(_, row) {
        return (
          <Button type="link" onClick={() => handleEdit(row)}>
            编辑
          </Button>
        );
      },
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const load = async () => {
    const { data, count } = await getCrushes({
      skip: (pagination.current - 1) * pagination.pageSize,
      take: pagination.pageSize,
    });
    const needLoadRanges = [];
    data.forEach((item) => {
      if (needLoadRanges.indexOf(item.range) === -1) {
        needLoadRanges.push(item.range);
      }
    });
    needLoadRanges.forEach((r) => dispatch(getRangeDetail(r)));
    setDataSource(data);
    setTotal(count);
  };
  const handlePaginationChange = (params) => {
    setPagination(params);
  };
  const handleSubmit = async () => {
    const values = await form.validateFields();
    await editCrush({
      id: model.id,
      ...values,
    });
    notification.success({ message: '修改成功' });
    load();
  };
  const handleModalClose = () => {
    setVisible(false);
  };
  const handleModalConfirm = async () => {
    await handleSubmit();
    setVisible(false);
  };
  const isRangeActive = (range) => {
    if (!ranges[range]) return false;
    const { start, end } = ranges[range];
    return dayjs().isBetween(dayjs(start), dayjs(end));
  };
  useEffect(() => {
    load();
  }, [pagination]);
  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);
  return (
    <>
      <Table rowKey="id" columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} />
      <Modal visible={visible} forceRender={true} width="50%" onOk={handleModalConfirm} onCancel={handleModalClose}>
        <Form initialValues={model} form={form} onFinish={handleSubmit}>
          <Form.Item
            name="title"
            label="名称"
            rules={[
              {
                required: true,
                message: '请输入名称',
              },
            ]}
          >
            <Input placeholder="请输入名称" />
          </Form.Item>
          <Form.Item label="材料">
            <Input.Group compact>
              <Form.Item name="gold" label="所需金矿数量" noStyle>
                <Input style={{ width: '25%' }} prefix="金矿" placeholder="请输入金矿数量" disabled={isRangeActive(model.range)} />
              </Form.Item>
              <Form.Item name="silver" label="所需银矿数量" noStyle>
                <Input style={{ width: '25%' }} prefix="银矿" placeholder="请输入银矿数量" disabled={isRangeActive(model.range)} />
              </Form.Item>
              <Form.Item name="copper" label="所需铜矿数量" noStyle>
                <Input style={{ width: '25%' }} prefix="铜矿" placeholder="请输入铜矿数量" disabled={isRangeActive(model.range)} />
              </Form.Item>
              <Form.Item name="diamond" label="所需钻石数量" noStyle>
                <Input style={{ width: '25%' }} prefix="钻石" placeholder="请输入钻石数量" disabled={isRangeActive(model.range)} />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item name="position" label="展示位置">
            <InputNumber placeholder="请输入展示位置" />
          </Form.Item>

          <Form.Item name="range" label="开放阶段">
            <Select style={{ width: 120 }} disabled={isRangeActive(model.range)}>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
            </Select>
          </Form.Item>
          <div className="flex flex-wrap">
            <div className={styles.uploads}>
              <div>未完成图片：</div>
              <Form.Item name={['images', 'unCompleted']} label="未完成图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>可打造图片：</div>
              <Form.Item name={['images', 'readyBuild']} label="可打造图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>已完成图片：</div>
              <Form.Item name={['images', 'completed']} label="已完成图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>图纸图片：</div>
              <Form.Item name={['images', 'print']} label="图纸图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>模具图片：</div>
              <Form.Item name={['images', 'mould']} label="模具图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>准备打磨图片：</div>
              <Form.Item name={['images', 'readySanding']} label="准备打磨图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>一颗钻石图片：</div>
              <Form.Item name={['images', 'diamond0']} label="一颗钻石图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>两颗钻石图片：</div>
              <Form.Item name={['images', 'diamond1']} label="两颗钻石图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
            <div className={styles.uploads}>
              <div>三颗钻石图片：</div>
              <Form.Item name={['images', 'diamond2']} label="三颗钻石图片" noStyle>
                <ImageUploader />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
